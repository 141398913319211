import Vue from 'vue'
import { DateTime } from 'luxon'

export default Vue.extend({
    data: function () {
        return {
            // visible is an object of dynamic key/value created when visibilityChanged is mounted or updates
            visible: {} as any,
        }
    },
    methods: {
        visibilityChanged: function (isVisible: boolean, componentName: string) {
            if (componentName in this.visible) {
                const event = isVisible ? 'event_scroll_visible' : 'event_scroll_hidden'
                window.logEvent(event, { time: DateTime.now().toUTC(), name: componentName })
                this.visible[componentName] = isVisible
            } else {
                // initialize the property and set to false (not visible) on first load
                this.visible[componentName] = false
            }
        },
    },
})
