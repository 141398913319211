<template>
    <tiny-slider
        :loop="true"
        prevent-scroll-on-touch="auto"
        items="1"
        nav-position="bottom"
        gutter="16"
        :controls="false"
    >
        <div>
            <card-comparison
                bank-name="Capital One Platinum"
                color="#C03F32"
                mid-apr="26.99%"
                monthly="$408.20"
                total="$14,695.15"
                saved="$130.42"
            />
        </div>
        <div>
            <card-comparison
                bank-name="Chase Freedom"
                color="#117ACA"
                mid-apr="19.3%"
                monthly="$368.08"
                total="$13,250.84"
                saved="$90.30"
            />
        </div>
        <div>
            <card-comparison
                bank-name="Bank of America Cash Rewards"
                color="#EE2A24"
                mid-apr="18.99%"
                monthly="$366.51"
                total="$13,194.35"
                saved="$88.73"
            />
        </div>
    </tiny-slider>
</template>

<script>
    import 'tiny-slider/src/tiny-slider.scss'
    import VueTinySlider from 'vue-tiny-slider'
    import CardComparison from '@/components/CardComparison'

    export default {
        name: 'CardComparisonCarousel',
        components: {
            'tiny-slider': VueTinySlider,
            'card-comparison': CardComparison,
        },
    }
</script>

<style lang="scss">
    @import '../styles/components/tinySlider';
</style>
